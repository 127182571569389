import { VueQueryPlugin } from '@tanstack/vue-query'
import PrimeVue from 'primevue/config'
import { createApp } from 'vue'
import { plugin as VueInputAutowidth } from 'vue-input-autowidth'
import Toast from 'vue-toastification'
import { abilityPlugin } from '@/modules/ability/plugins/ability'
import { createAppsignalErrorHandler } from '@/modules/base/composable/useAppsignal'
import { setupI18n } from '@/modules/base/composable/useI18nManager'
import { createFocusDirective } from '@/modules/base/directives/focus'
import { createTooltipDirective } from '@/modules/base/directives/tooltip'
import App from './App.vue'
import { registerGlobalComponents } from './globalComponents'
import pinia from './pinia'
import { lightTheme } from './plugins/lightTheme'
import { posthogPlugin } from './plugins/posthog'
import { primeVueOptions } from './plugins/primeVue'
import { vueQueryConfig } from './queryConfig'
import router from './router'
import toastConfig from './toastConfig'
import 'tippy.js/dist/tippy.css'
import '@vuepic/vue-datepicker/dist/main.css'
import { cookieConsent } from './utils/cookieConsent'

export default (): void => {
  document.addEventListener('DOMContentLoaded', () => {
    cookieConsent()

    // find out more about backend routes: https://github.com/Ragnarson/global_changer_web/pull/494
    if (!document.getElementById('vue-app')) {
      // prevent backend routes from mounting front-end layer
      return
    }

    const app = createApp(App)
    app.config.errorHandler = createAppsignalErrorHandler()
    app.use(PrimeVue, primeVueOptions)
    app.use(VueInputAutowidth)
    app.use(VueQueryPlugin, vueQueryConfig)
    app.use(router)
    app.use(setupI18n())
    app.use(pinia)
    app.use(Toast, toastConfig)
    app.use(registerGlobalComponents)
    app.use(lightTheme)
    app.use(posthogPlugin)
    app.use(abilityPlugin)
    app.directive('tooltip', createTooltipDirective())
    app.directive('focus', createFocusDirective())
    app.mount('#vue-app')
  })
}
