<script setup lang="ts">
  import useVuelidate from '@vuelidate/core'
  import { reactive, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import {
    ActionForBackendData,
    useMutationActionCreate,
  } from '@/api/useAction'

  import BaseInput from '@/modules/base/components/BaseInput.vue'
  import BaseLink from '@/modules/base/components/BaseLink.vue'
  import BaseTextarea from '@/modules/base/components/BaseTextarea.vue'
  import {
    ModalQuery,
    useModalView,
  } from '@/modules/base/composable/useModalView'
  import { Route } from '@/modules/base/config/routesConfig'
  import { DeepPartial } from '@/modules/base/utils/typescript'
  import { useDates } from '@/modules/shared/composable/useDates'
  import { useI18nValidators } from '@/modules/shared/utils/i18n-validators'
  import ActionHeaderAssignCategory from './ActionHeaderAssignCategory.vue'
  import ActionHeaderAssignScope from './ActionHeaderAssignScope.vue'
  import ActionHeaderRoadmapPosition from './ActionHeaderRoadmapPosition.vue'

  const { currentYear, currentQuarter } = useDates()
  const action = reactive<DeepPartial<ActionForBackendData>>({
    targetYear: currentYear.value,
    targetQuarter: currentQuarter.value,
  })
  const v$ = useVuelidate()

  const { required } = useI18nValidators()

  const { mutateAsync: create } = useMutationActionCreate()
  const router = useRouter()

  const { close } = useModalView(ModalQuery.ActionCreate)

  const createAndRedirect = async () => {
    if (!(await v$.value.$validate())) {
      return
    }

    const returned = await create(action)

    await router.push({
      name: Route.Action,
      params: { id: returned.id },
    })
  }

  const descriptionModel = computed({
    get: () => action.description ?? undefined,
    set: (value) => (action.description = value),
  })
</script>

<template>
  <div class="flex flex-col gap-12" data-testid="action-form">
    <BaseInput
      v-model="action.title"
      data-testid="action-form-title"
      :label="$t('global.form.nameAction')"
      name="title"
      :validation="{ required }"
    />

    <div class="grid grid-cols-[repeat(2,22rem)] gap-7">
      <ActionHeaderAssignScope
        :action
        class="w-min"
        required
        @assign="
          ;[(action.scope = $event.scope), (action.subscope = $event.subscope)]
        "
      />
      <ActionHeaderRoadmapPosition
        class="w-min"
        required
        :value="action"
        @assign="
          ;[
            (action.targetYear = $event.targetYear),
            (action.targetQuarter = $event.targetQuarter),
          ]
        "
      />
      <ActionHeaderAssignCategory
        :action
        class="w-min"
        required
        @assign="action.category = $event"
      />
    </div>

    <BaseTextarea
      id="description"
      v-model="descriptionModel"
      data-testid="action-form-description"
      :label="$t('global.form.description')"
      :placeholder="$t('global.form.descriptionPlaceholder')"
    />

    <div class="flex flex-col items-center gap-4">
      <BaseButton
        data-testid="action-form-submit"
        primary
        @click="createAndRedirect"
      >
        {{ $t('global.form.save') }}
      </BaseButton>

      <BaseButton data-testid="action-form-cancel" secondary @click="close">
        {{ $t('global.form.cancel') }}
      </BaseButton>

      <BaseLink secondary :to="{ name: Route.AssessmentActionsScopeDefault }">
        {{ $t('global.form.assessmentActionsLink') }}
      </BaseLink>
    </div>
  </div>
</template>
